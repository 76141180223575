<template>
  <section class="mt-5">
    <div v-if="loading">
      <Loading />
    </div>
    <form>
      <div v-if="!loading" class="offmarket">
        <v-row>
          <!-- Image Section -->
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Image Gallery</span>
                <img :src="property.image" alt="" width="100%" />
              </div>
            </div>
          </v-col>
          <v-col md="12">
            <ul class="images">
              <li
                v-for="(ele, index) in property.images"
                :key="index"
                style="position: relative"
              >
                <span
                  ><v-icon
                    v-if="ele.id"
                    class="closeBtn"
                    @click="deleteImageAct(ele.id)"
                  >
                    mdi-close</v-icon
                  >
                </span>
                <img :src="ele.url" alt="" width="200" />
              </li>
            </ul>
          </v-col>
          <v-col md="12" cols="12" class="pt-8 mb-3">
            <UploadImage
              property="offmarket"
              :id="property._id"
              @uploadedAct="refetchData"
            />
          </v-col>
        </v-row>
        
        <!-- Address Details Section -->
          <div class="section hover-section">
            <h3>Address Details</h3>
            <v-row>
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Door Number</span>
                    <h2>
                      <v-text-field v-model="property.doorNumber" outlined />
                    </h2>
                  </div>
                </div>
              </v-col>
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Address</span>
                    <h2>
                      <v-text-field v-model="property.address" outlined />
                    </h2>
                  </div>
                </div>
              </v-col>
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Region</span>
                    <h2>
                      <v-text-field v-model="property.region" outlined />
                    </h2>
                  </div>
                </div>
              </v-col>
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Town</span>
                    <h2>
                      <v-select
                        v-model="property.town"
                        :items="property.towns"
                        item-text="town"
                        item-value="town"
                        outlined
                      >
                      </v-select>
                    </h2>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn 
                  v-if="!loadingUpdate" 
                  @click="updatePropertyAct('address')"
                  class="update-btn"
                >
                  Update Address Details
                </v-btn>
                <v-btn v-if="loadingUpdate" loading class="update-btn"></v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- Financial Details Section -->
          <div class="section hover-section">
            <h3>Financial Details</h3>
            <v-row>
              <!-- Lock Icon Button -->
              <v-col cols="12" class="d-flex justify-end mb-2">
                <v-btn icon @click="fieldsLocked ? showLockModal = true : unlockFields()" class="lock-icon">
                  <v-icon>{{ fieldsLocked ? 'mdi-lock' : 'mdi-lock-open' }}</v-icon>
                </v-btn>
              </v-col>

              <!-- Purchase Price -->
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Purchase Price</span>
                    <h2>
                      <v-text-field 
                        :value="formatCurrency(property.purchasePrice)"
                        @input="updateFieldValue('purchasePrice', $event)"
                        outlined
                      />
                    </h2>
                  </div>
                </div>
              </v-col>

              <!-- Zoopla Valuation -->
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Zoopla Valuation</span>
                    <h2>
                      <v-text-field 
                        :value="formatCurrency(property.price)"
                        @input="updateFieldValue('price', $event)"
                        outlined
                      />
                    </h2>
                  </div>
                </div>
              </v-col>

              <!-- Current Rent -->
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Current Rent</span>
                    <h2>
                      <v-text-field 
                        :value="formatCurrency(property.currentRent)"
                        @input="updateFieldValue('currentRent', $event)"
                        outlined
                      />
                    </h2>
                  </div>
                </div>
              </v-col>

              <!-- Estimated Rent -->
              <v-col md="3">
                <div class="flexBox iconText">
                  <div>
                    <span>Estimated Rent</span>
                    <h2>
                      <v-text-field 
                        :value="formatCurrency(property.averageRent)"
                        @input="updateFieldValue('averageRent', $event)"
                        outlined
                      />
                    </h2>
                  </div>
                </div>
              </v-col>

              <!-- Fields Below this Point Will Be Locked by Default -->

              <!-- Deposit -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Deposit</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('deposite')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Investment -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Investment</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('totalInvestment')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Mortgage Label -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Mortgage Label</span>
                      <h2>
                        <v-text-field 
                          v-model="property.mortgageLabel" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Mortgage -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Mortgage</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('mortgage')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Reservation Fee -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Reservation Fee</span>
                      <h2>
                        <v-text-field 
                          :value="formatCurrency(property.reservationPrice)"
                          @input="updateFieldValue('reservationPrice', $event)"
                          outlined
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Brokerage -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Brokerage (1% of borrowing)</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('brokerage')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Management Fees -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Management Fees</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('management')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Operating Expenses -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Operating Expenses</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('opex')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Stamp Duty -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Stamp Duty</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('stampDuty')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Legal Fees -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Legal Fees</span>
                      <h2>
                        <v-text-field 
                          :value="formatCurrency(property.legals)"
                          @input="updateFieldValue('legals', $event)"
                          outlined
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Valuation & Mortgage Set Up Fees -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Valuation & Mortgage Set Up Fees</span>
                      <h2>
                        <v-text-field 
                          :value="formatCurrency(property.setupFee)"
                          @input="updateFieldValue('setupFee', $event)"
                          outlined
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Discount Label -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Discount Label</span>
                      <h2>
                        <v-text-field 
                          :value="property.discountLabel" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Discount -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Discount</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('discount')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Net Cashflow -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Net Cashflow</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('netCashflow')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Net Annual Cashflow -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Net Annual Cashflow</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('netAnnualCashflow')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>

                <!-- Gross Yield -->
                <v-col md="3">
                  <div class="flexBox iconText">
                    <div>
                      <span>Gross Yield</span>
                      <h2>
                        <v-text-field 
                          :value="getFieldValue('grossYield')" 
                          outlined 
                          :readonly="fieldsLocked"
                          :class="{ 'locked-field-container': fieldsLocked }"
                        />
                      </h2>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- Update Button -->
              <v-row>
                <v-col class="text-center">
                  <v-btn 
                    v-if="!loadingUpdate" 
                    @click="updatePropertyAct('financial')"
                    class="update-btn"
                  >
                    Update Financial Details
                  </v-btn>
                  <v-btn v-if="loadingUpdate" loading class="update-btn"></v-btn>
                </v-col>
              </v-row>

              <!-- Lock Modal -->
              <v-dialog v-model="showLockModal" max-width="400px">
                <v-card>
                  <v-card-title>Unlock Fields</v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="lockPassword"
                      label="Password"
                      type="password"
                      @keyup.enter="unlockFields"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showLockModal = false" class="modal-btn">
                      <span>Cancel</span>
                    </v-btn>
                    <v-btn text @click="unlockFields" class="modal-btn">
                      <span>Unlock</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>


          <!-- Other Details Section -->
          <div class="section hover-section">
            <h3>Other Details</h3>
          <v-row>
            <v-col md="3">
              <div class="flexBox iconText">
                <div>
                  <span>Tenure</span>
                  <h2>
                    <v-select
                      v-model="property.tenureType"
                      :items="[
                        { text: 'Freehold', value: 'Freehold' },
                        { text: 'Leasehold', value: 'Leasehold' }
                      ]"
                      item-text="text"
                      item-value="value"
                      outlined
                    />
                  </h2>
                </div>
              </div>
            </v-col>
            <v-col md="3">
              <div class="flexBox iconText">
                <div>
                  <span>Property Type</span>
                  <h2>
                    <v-select
                      v-model="property.propertyType"
                      :items="[
                        { text: 'Apartment', value: 'Apartment' },
                        { text: 'Apartment Block', value: 'Apartment Block' },
                        { text: 'Bungalow', value: 'Bungalow' },
                        { text: 'Detached House', value: 'Detached House' },
                        { text: 'Mixed-Commercial and Resi', value: 'Mixed-Commercial and Resi' },
                        { text: 'Portfolio', value: 'Portfolio' },
                        { text: 'Semi Commercial', value: 'Semi Commercial' },
                        { text: 'Semi-Detached House', value: 'Semi-Detached House' },
                        { text: 'Terraced House', value: 'Terraced House' }
                      ]"
                      item-text="text"
                      item-value="value"
                      outlined
                    />
                  </h2>
                </div>
              </div>
            </v-col>
            <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Strategy</span>
                <h2>
                  <v-select
                    v-model="property.strategy"
                    :items="[
                      { text: 'Buy To Let', value: 'Buy To Let' },
                      { text: 'Development Opportunity', value: 'Development Opportunity' },
                      { text: 'HMO', value: 'HMO' },
                      { text: 'Housing Association', value: 'Housing Association' },
                      { text: 'Portfolio', value: 'Portfolio' },
                      { text: 'Serviced Accommodation', value: 'Serviced Accommodation' }
                    ]"
                    item-text="text"
                    item-value="value"
                    outlined
                  />
                </h2>
              </div>
            </div>
          </v-col>

          <!-- Current Occupancy Dropdown -->
            <v-col md="3">
              <div class="flexBox iconText">
                <div>
                  <span>Current Occupancy</span>
                  <h2>
                    <v-select
                      v-model="property.occupancy"
                      :items="[
                        { text: 'Tenanted', value: 'Tenanted' },
                        { text: 'Vacant', value: 'Vacant' }
                      ]"
                      item-text="text"
                      item-value="value"
                      outlined
                    />
                  </h2>
                </div>
              </div>
            </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Bedrooms</span>
                <h2>
                  <v-text-field v-model="property.bedrooms" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
          <div class="flexBox iconText">
            <div>
              <span>Condition</span>
              <h2>
                <v-select
                  v-model="property.condition"
                  :items="[
                    { text: 'Good', value: 'Good' },
                    { text: 'Fair', value: 'Fair' },
                    { text: 'Needs Minor Work', value: 'Needs Minor Work' },
                    { text: 'Needing Refurbishment', value: 'Needing Refurbishment' }
                  ]"
                  item-text="text"
                  item-value="value"
                  outlined
                />
              </h2>
            </div>
          </div>
        </v-col>
        <v-col md="3">
          <div class="flexBox iconText data-field">
            <div class="field-header">
              <span>EPC Rating</span>
              <v-btn 
                class="add-data-btn" 
                @click="fetchEPCData()"
                :loading="loadingEPCData"
                small
              >
                ADD DATA
              </v-btn>
            </div>
            <v-text-field
              v-model="property.epc"
              outlined
              class="hover-field"
              :readonly="true"
            />
          </div>
        </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Floor Area</span>
                <h2>
                  <v-text-field
                    v-model="property.floorsqft"
                    outlined
                    ref="floorArea"
                    :readonly="property.epc !== 'No EPC Available'"
                  />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText data-field">
              <div class="field-header">
                <span>Flood Risk</span>
                <v-btn 
                  class="add-data-btn" 
                  @click="fetchFloodRiskData()" 
                  :loading="loadingFloodRiskData"
                  small
                >
                  ADD DATA
                </v-btn>
              </div>
              <v-text-field
                v-model="property.floodRisk"
                outlined
                class="hover-field"
                readonly
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn 
              v-if="!loadingUpdate" 
              @click="updatePropertyAct('address')"
              class="update-btn"
            >
              Update Other Details
            </v-btn>
            <v-btn v-if="loadingUpdate" loading class="update-btn"></v-btn>
          </v-col>
        </v-row>
        </div>

        <!-- EPC Modal -->
        <v-dialog v-model="showPropertyDialog" max-width="500px">
          <v-card>
            <v-card-title>Select Property</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="(propertyItem, index) in propertyList"
                  :key="index"
                  @click="selectProperty(propertyItem)"
                  :class="{ 'blue lighten-4': propertyItem.selected }"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ formatAddress(propertyItem) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
          

        <!-- Additional Details Section -->
        <div class="section hover-section">
          <h3>Additional Details</h3>
        <v-row>
          <v-col md="3">
          <div class="flexBox iconText school-section">
            <span>Nearest Schools</span>
            <v-btn 
              class="mb-2 add-data-btn" 
              @click="fetchSchoolData"
              :loading="loadingSchoolData"
              small
            >
              Add Data
            </v-btn>
            <v-row v-if="property.schools && property.schools.length > 0">
              <v-col>
                <v-text-field
                  v-for="(data, index) in property.schools"
                  :key="index"
                  v-model="data.school"
                  outlined
                  class="hover-field"
                  :readonly="!schoolDataFetchFailed"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-for="(data, index) in property.schools"
                  :key="index"
                  v-model="data.distance"
                  outlined
                  class="hover-field"
                  :readonly="!schoolDataFetchFailed"
                />
              </v-col>
            </v-row>
            <v-row v-else-if="schoolDataFetchFailed">
              <v-col>
                <v-text-field
                  v-for="index in 3"
                  :key="index"
                  v-model="property.schools[index-1].school"
                  outlined
                  class="hover-field"
                  placeholder="School name"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-for="index in 3"
                  :key="index"
                  v-model="property.schools[index-1].distance"
                  outlined
                  class="hover-field"
                  placeholder="Distance"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <p>No school data available. Click 'Add Data' to fetch.</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
          
        <v-col md="3">
        <div class="flexBox iconText school-section">
          <span>Previously Sold - Same Street</span>
          <v-btn 
            class="mb-2 add-data-btn" 
            @click="fetchSameStreetData()"
            :loading="loadingSameStreetData"
            small
          >
            Add Data
          </v-btn>
              <v-row v-if="property.onStreetCompare.length > 0">
                <v-col>
                  <v-text-field
                    v-for="(data, index) in property.onStreetCompare"
                    :key="index"
                    v-model="property.onStreetCompare[index]"
                    outlined
                    class="hover-field"
                    :readonly="!sameStreetDataFetchFailed"
                  />
                </v-col>
              </v-row>
              <v-row v-else-if="sameStreetDataFetchFailed">
                <v-col>
                  <v-text-field
                    v-for="index in 3"
                    :key="index"
                    v-model="property.onStreetCompare[index-1]"
                    outlined
                    class="hover-field"
                    placeholder="Previously Sold Data"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <p>No data available.</p>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col md="3">
            <div class="flexBox iconText school-section">
              <div class="field-header">
                <span>Previously Sold - Within Same Area</span>
                <v-btn 
                  class="add-data-btn" 
                  @click="fetchSameAreaData()" 
                  :loading="loadingSameAreaData"
                  small
                >
                  ADD DATA
                </v-btn>
              </div>
              <v-text-field
                v-for="(data, index) in property.onDistrictCompare"
                :key="index"
                v-model="property.onDistrictCompare[index]"
                outlined
                class="hover-field"
                readonly
              />
            </div>
          </v-col>


          <v-col md="3">
            <div class="flexBox iconText school-section">
              <div class="field-header">
                <span>For Sale - Within The Area</span>
                <v-btn 
                  class="add-data-btn" 
                  @click="fetchForSaleAreaData()" 
                  :loading="loadingForSaleAreaData"
                  small
                >
                  ADD DATA
                </v-btn>
              </div>
              <v-text-field
                v-for="(data, index) in property.onForSaleCompare"
                :key="index"
                v-model="property.onForSaleCompare[index]"
                outlined
                class="hover-field"
                readonly
              />
            </div>
          </v-col>


          <v-col md="3">
            <div class="flexBox iconText school-section">
              <div class="field-header">
                <span>For Rent - Within The Area</span>
                <v-btn 
                  class="add-data-btn" 
                  @click="fetchForRentAreaData()" 
                  :loading="loadingForRentAreaData"
                  small
                >
                  ADD DATA
                </v-btn>
              </div>
              <v-text-field
                v-for="(data, index) in property.onForRentCompare"
                :key="index"
                v-model="property.onForRentCompare[index]"
                outlined
                class="hover-field"
                readonly
              />
            </div>
          </v-col>

          <v-col md="3">
            <div class="flexBox iconText school-section">
              <div>
                <span>Nearest Stations</span>
                <v-btn 
                  class="add-data-btn"
                  @click="fetchNearestStations"
                  :loading="loadingStationsData"
                  small
                >
                  ADD DATA
                </v-btn>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-for="(data, index) in property.stations"
                      :key="index"
                      v-model="data.station"
                      outlined
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-for="(data, index) in property.stations"
                      :key="index"
                      v-model="data.distance"
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>


          <v-col md="3">
          <div class="flexBox iconText deal-benefit-section ">
            <div>
              <span>Deal Benefit</span>
              <div class="deal-benefit-spacer"></div>
              <v-text-field
                v-for="(benefit, index) in dealBenefitText"
                :key="index"
                :value="benefit"
                outlined
                readonly
              />
            </div>
          </div>
        </v-col>
        </v-row>
        
        <v-row>
      <v-col class="text-center">
        <v-btn 
          v-if="!loadingUpdate" 
          @click="updatePropertyAct('address')"
          class="update-btn"
        >
          Update Additional Details
        </v-btn>
        <v-btn v-if="loadingUpdate" loading class="update-btn"></v-btn>
      </v-col>
    </v-row>
        </div>

        <!-- Description Section -->
        <div class="section hover-section">
          <h3>Description</h3>
        <v-row>
          <v-col md="12">
            <div class="flexBox iconText">
              <div>
                <span>Property Description</span>
                <h2>
                  <v-textarea
                    v-model="property.propertyDescription"
                    outlined
                  ></v-textarea>
                </h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn 
              v-if="!loadingUpdate" 
              @click="updatePropertyAct('address')"
              class="update-btn"
            >
              Update Description
            </v-btn>
            <v-btn v-if="loadingUpdate" loading class="update-btn"></v-btn>
          </v-col>
        </v-row>
        </div>
        
      </div>
    </form>
    <v-snackbar v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </section>
</template>


<!-- No Longer Used --
          
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Council Tax Band</span>
                <h2>
                  <v-text-field v-model="property.councilTax" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Post TItle</span>
                <h2>
                  <v-text-field v-model="property.propertyOf" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Refurbishment Cost</span>
                <h2>
                  <v-text-field v-model="property.refurbishmentCost" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Rental Income</span>
                <h2>
                  <v-text-field v-model="property.rentalIncome" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Land Size</span>
                <h2>
                  <v-text-field v-model="property.sizing" outlined />
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Refurbishment</span>

                <v-text-field
                  v-for="(data, index) in property.refurbishment"
                  :key="index"
                  v-model="property.refurbishment[index]"
                  outlined
                />
              </div>
            </div>
          </v-col>
           -->
        </v-row>
      </div>
    </form>
    <v-snackbar v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </section>
</template>


<script>
import Loading from "@/components/dashboard/Loading";
import PropertyUpdate from "@/components/dashboard/PropertyUpdate";
import { mapActions, mapGetters } from "vuex";
import UploadImage from "@/components/dashboard/ImageUpload";

export default {
  name: "SinglePropertyMarket",
  components: {
    PropertyUpdate,
    Loading,
    UploadImage,
  },
  data: () => ({
    property: {
      images: [],
      schools: [],
      stations: [],
      onStreetCompare: [],
      onDistrictCompare: [],
      onForSaleCompare: [],
      onForRentCompare: [],
      onBenefits: [],
      purchasePrice: 0,            // Purchase price of the property
      price: 0,                    // Zoopla Valuation
      currentRent: 0,              // Current rent
      averageRent: 0,              // Estimated rent
      deposite: 0,                 // Deposit (calculated)
      brokerage: 0,                // Brokerage (calculated)
      mortgage: 0,                 // Mortgage (calculated)
      reservationPrice: 100,       // Reservation price (manually input)
      management: 0,               // Management fees (calculated)
      opex: 0,                     // Operating expenses (calculated)
      stampDuty: 0,                // Stamp duty (calculated)
      legals: 2000,                // Legal fees (manually input)
      setupFee: 500,               // Valuation and mortgage setup fee (manually input)
      netAnnualCashflow: 0,        // Net annual cashflow (calculated)
      grossYield: 0,               // Gross yield (calculated)
      mortgageLabel: 'N/A',           // Mortgage label (static or manually input)
      totalInvestment: 0,          // Total investment (calculated)
    },
    loading: true,
    loadingUpdate: false,
    snackbar: false,
    message: "",
    showPropertyDialog: false,
    loadingEPCData: false,
    loadingFloodRiskData: false,
    propertyList: [],
    showLockModal: false,
    lockPassword: '',
    fieldsLocked: true,
    loadingSchoolData: false,
    schoolDataFetchFailed: false,
    loadingSameStreetData: false,
    sameStreetDataFetchFailed: false,
    loadingForSaleAreaData: false,
    forSaleAreaDataFetchFailed: false,
    sameAreaDataFetchFailed: false,
    loadingForSaleAreaData: false,
    forSaleAreaDataFetchFailed: false,
    loadingSoldPricesData: false,
    loadingAreaSoldPricesData: false,
    loadingNearestStations: false,

  }),
  computed: {
    ...mapGetters(["allOffMarketProperty"]),

    dealBenefitText() {
    let benefit = ['Off Market Opportunity'];

    if (this.property.discount > 0) {
      benefit.push(this.property.discountLabel);
    } else {
      benefit.push(this.property.occupancy === 'Tenanted' ? 'Property is Tenanted' : 'Great Investment Area');
    }

    const yieldValue = this.property.grossYield.toFixed(2);
    if (this.property.strategy === 'Housing Association') {
      benefit.push(`Net Yield ${yieldValue}%`);
    } else {
      benefit.push(`Gross Yield ${yieldValue}%`);
    }

    return benefit;
  }
},

  methods: {
    ...mapActions(["getProperty", "putPropertyOffMarket", "deleteImage"]),

    numberFormat(givenNumber) {
      return Math.ceil(givenNumber).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
      });
    },

    async refetchData() {
      await this.getProperty(this.$route.params.id);
      this.property = this.allOffMarketProperty.results;
    },

    async updatePropertyAct() {
      this.loadingUpdate = true;
      await this.putPropertyOffMarket({
        _id: this.property._id,
        address: this.property.address,
        averageRent: this.property.averageRent,
        bedrooms: this.property.bedrooms,
        brokerage: this.property.brokerage,
        currentRent: this.property.currentRent,
        deposite: this.property.deposite,
        discount: this.property.discount,
        discountLabel: this.property.discountLabel,
        epc: this.property.epc,
        floodRisk: this.property.floodRisk,
        floorsqft: this.property.floorsqft,
        grossYield: this.property.grossYield,
        legals: this.property.legals,
        management: this.property.management,
        mortgage: this.property.mortgage,
        netAnnualCashflow: this.property.netAnnualCashflow,
        netReturn: this.property.netReturn,
        occupancy: this.property.occupancy,
        opex: this.property.opex,
        price: this.property.price,
        propertyType: this.property.propertyType,
        purchasePrice: this.property.purchasePrice,
        setupFee: this.property.setupFee,
        stampDuty: this.property.stampDuty,
        tenureType: this.property.tenureType,
        totalInvestment: this.property.totalInvestment,
        doorNumber: this.property.doorNumber,
        benefits: this.property.onBenefits,
        stations: this.property.stations,
        onForRentCompare: this.property.onForRentCompare,
        onForSaleCompare: this.property.onForSaleCompare,
        onDistrictCompare: this.property.onDistrictCompare,
        onStreetCompare: this.property.onStreetCompare,
        reservationPrice: this.property.reservationPrice,
        strategy: this.property.strategy,
        mortgageLabel: this.property.mortgageLabel,
        propertyDescription: this.property.propertyDescription,
        town: this.property.town,
        schools: this.property.schools,
      });
      this.loadingUpdate = false;
      this.snackbar = true;
      this.message = "Property Updated";
    },

    async deleteImageAct(id) {
      await this.deleteImage(id);
      this.property.images = this.property.images.filter(
        (image) => image.id !== id
      );
      this.snackbar = true;
      this.message = "Image Deleted";
    },

    //Lock

      unlockFields() {
      console.log('Unlock/Lock method called');
      if (this.fieldsLocked) {
        // Currently locked, trying to unlock
        if (this.lockPassword === '@Dylan143') {
          console.log('Correct password, unlocking');
          this.fieldsLocked = false;
          this.showLockModal = false;
          this.lockPassword = '';
          this.snackbar = true;
          this.message = "Fields unlocked";
        } else {
          console.log('Incorrect password');
          this.snackbar = true;
          this.message = "Incorrect password";
        }
      } else {
        // Currently unlocked, locking again
        console.log('Locking fields');
        this.fieldsLocked = true;
        this.snackbar = true;
        this.message = "Data Now Locked";
      }
    },

    //Format Distance

      formatDistance(distance) {
        let formattedDistance;
        if (typeof distance === 'number') {
          formattedDistance = distance.toFixed(2);
        } else if (typeof distance === 'string') {
          const numDistance = parseFloat(distance);
          formattedDistance = isNaN(numDistance) ? distance : numDistance.toFixed(2);
        } else {
          formattedDistance = 'N/A';
        }
        return formattedDistance + ' Miles';
      },


    //EPC

    async fetchEPCData() {
      this.loadingEPCData = true;
      console.log("Fetching EPC data...");
      const address = this.property.address;
      let postcode = address.split(', ').pop().replace(/\s/g, '');

      console.log("Extracted postcode:", postcode);

      try {
        const url = `https://epc.opendatacommunities.org/api/v1/domestic/search?postcode=${postcode}`;
        console.log("API URL:", url);

        const response = await fetch(url, {
          headers: {
            'Accept': 'text/csv',
            'Authorization': 'Basic cGF1bEBvcGFyby5jby51azpiZGU4ZTBkOWE4Y2Y3OWE1YWRiNzQ3Mzc4YmIxZmUyNjI3ZmMwMjFl'
          }
        });
        console.log("Response status:", response.status);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const csvText = await response.text();
        console.log("CSV response:", csvText.substring(0, 200) + "..."); // Log first 200 characters of the response
        const properties = this.parseCSV(csvText);

        // Add "No EPC Available" option at the beginning of the properties array
        properties.unshift({ address1: "No EPC Available", postcode: "", isNoEPC: true });

        this.showPropertySelectionDialog(properties);
      } catch (error) {
        console.error('Error fetching EPC data:', error);
        this.snackbar = true;
        this.message = "Error fetching EPC data: " + error.message;
      } finally {
        this.loadingEPCData = false;
      }
    },

    parseCSV(csvText) {
      const lines = csvText.split('\n').filter(line => line.trim() !== '');
      const headers = this.parseCsvLine(lines[0]);

      const properties = lines.slice(1).map(line => {
        const values = this.parseCsvLine(line);
        return headers.reduce((obj, header, index) => {
          obj[header.trim()] = values[index] || '';
          return obj;
        }, {});
      }).filter(prop => prop['address1'] && prop['address1'].trim() !== '');

      return properties.sort((a, b) => {
        const addressA = a['address1'].match(/\d+/) ? parseInt(a['address1'].match(/\d+/)[0]) : 0;
        const addressB = b['address1'].match(/\d+/) ? parseInt(b['address1'].match(/\d+/)[0]) : 0;
        return addressA - addressB;
      });
    },

    parseCsvLine(line) {
      const result = [];
      let startValueIndex = 0;
      let inQuotes = false;

      for (let i = 0; i < line.length; i++) {
        if (line[i] === '"') {
          inQuotes = !inQuotes;
        } else if (line[i] === ',' && !inQuotes) {
          result.push(line.slice(startValueIndex, i).replace(/^"|"$/g, '').trim());
          startValueIndex = i + 1;
        }
      }

      result.push(line.slice(startValueIndex).replace(/^"|"$/g, '').trim());
      return result;
    },

    showPropertySelectionDialog(properties) {
      this.propertyList = properties;
      this.showPropertyDialog = true;
    },

    selectProperty(property) {
      if (property.isNoEPC) {
        this.handleNoEPC();
      } else {
        this.updateEPCData(property);
      }
      this.showPropertyDialog = false;
    },

    handleNoEPC() {
      this.property.epc = "No EPC Available";
      this.property.floorsqft = "";
      this.makePropertySizeEditable();
    },

    makePropertySizeEditable() {
      const floorAreaField = this.$refs.floorArea;
      if (floorAreaField) {
        floorAreaField.readonly = false;
      }
    },

    updateEPCData(selectedProperty) {
      console.log('Selected Property Data:', selectedProperty);

      const inspectionYear = this.getYear(selectedProperty['inspection-date']);
      const currentRating = selectedProperty['current-energy-rating'];
      const currentEfficiency = selectedProperty['current-energy-efficiency'];
      const potentialRating = selectedProperty['potential-energy-rating'];
      const potentialEfficiency = selectedProperty['potential-energy-efficiency'];

      this.property.epc = this.formatEPCRating(inspectionYear, currentEfficiency, currentRating, potentialEfficiency, potentialRating);

      const squareMeters = parseFloat(selectedProperty['total-floor-area']);
      if (!isNaN(squareMeters)) {
        const squareFeet = Math.ceil(squareMeters * 10.76391);
        this.property.floorsqft = `${squareFeet} Sq. Ft. (${squareMeters} Sq.M.)`;
      } else {
        this.property.floorsqft = 'N/A';
      }
    },

    formatEPCRating(year, currentEff, currentRating, potentialEff, potentialRating) {
      return `(${year || 'N/A'}) ${currentEff || 'N/A'} (${currentRating || 'N/A'}) Potential ${potentialRating || 'N/A'} (${potentialEff || 'N/A'})`;
    },

    getYear(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return isNaN(date.getFullYear()) ? 'N/A' : date.getFullYear();
    },

    formatAddress(property) {
      return `${property.address1}, ${property.postcode}`.trim();
    },

    //Flood Risk

    async fetchFloodRiskData() {
        this.loadingFloodRiskData = true;
        console.log("Fetching flood risk data...");
        const address = this.property.address;
        let postcode = address.split(', ').pop().replace(/\s/g, '+');
        
        console.log("Extracted postcode:", postcode);
        
        try {
          const url = `https://api.propertydata.co.uk/flood-risk?key=PWQYTWQ1NQ&postcode=${postcode}`;
          console.log("API URL:", url);
          
          const response = await fetch(url);
          console.log("Response status:", response.status);
          
          const data = await response.json();
          console.log("API response:", data);
          
          if (data.status === 'success' && data.flood_risk) {
            this.property.floodRisk = data.flood_risk;
            console.log("Flood risk data fetched successfully:", this.property.floodRisk);
            this.snackbar = true;
            this.message = "Flood risk data added successfully";
          } else {
            console.error('Failed to fetch flood risk data:', data);
            this.snackbar = true;
            this.message = "Failed to fetch flood risk data: " + (data.message || 'Unknown error');
          }
        } catch (error) {
          console.error('Error fetching flood risk data:', error);
          this.snackbar = true;
          this.message = "Error fetching flood risk data: " + error.message;
        } finally {
          this.loadingFloodRiskData = false;
        }
      },


    //Schools

    async fetchSchoolData() {
      this.loadingSchoolData = true;
      this.schoolDataFetchFailed = false;
      console.log("Fetching school data...");
      const address = this.property.address;
      let postcode = address.split(', ').pop().replace(/\s/g, '');

      console.log("Extracted postcode:", postcode);

      try {
        const url = `https://api.propertydata.co.uk/schools?key=PWQYTWQ1NQ&postcode=${postcode}`;
        console.log("API URL:", url);

        const response = await fetch(url);
        console.log("Response status:", response.status);

        const data = await response.json();
        console.log("API response:", data);

        if (data.status === 'success' && data.data && data.data.state && data.data.state.nearest) {
          const nearestSchools = data.data.state.nearest.slice(0, 3);
          this.property.schools = nearestSchools.map(school => ({
            school: school.name,
            distance: this.formatDistance(school.distance)
          }));
          console.log("School data fetched successfully:", this.property.schools);
          this.snackbar = true;
          this.message = "School data fetched successfully";
        } else {
          throw new Error(data.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
        this.snackbar = true;
        this.message = "Failed to fetch school data: " + error.message;
        this.schoolDataFetchFailed = true;

        // Initialize empty fields for manual entry
        this.property.schools = [
          { school: '', distance: '' },
          { school: '', distance: '' },
          { school: '', distance: '' }
        ];
      } finally {
        this.loadingSchoolData = false;
      }
    },

  //Previously Sold - Same Street

  async fetchSameStreetData() {
    this.loadingSameStreetData = true;
    this.sameStreetDataFetchFailed = false;
    console.log("Fetching previously sold - same street data...");
    const address = this.property.address;
    let postcode = address.split(', ').pop().replace(/\s/g, '');
    
    console.log("Extracted postcode:", postcode);
    
    try {
      const url = `https://api.propertydata.co.uk/sold-prices?key=PWQYTWQ1NQ&postcode=${postcode}&max_age=84`;
      console.log("API URL:", url);
      
      const response = await fetch(url);
      console.log("Response status:", response.status);
      
      const data = await response.json();
      console.log("API response:", data);
      
      if (data.status === 'success' && data.data && data.data.raw_data) {
        // Filter and sort the properties by date in descending order
        const soldProperties = data.data.raw_data
          .filter(property => property.address.includes(address.split(', ')[0]))
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3); // Take only the top 3 results
        
        this.property.onStreetCompare = soldProperties.map(property => 
          `${property.address} - £${property.price.toLocaleString()} - ${property.date}`
        );
        console.log("Same street data fetched successfully:", this.property.onStreetCompare);
        this.snackbar = true;
        this.message = "Same street data fetched successfully";
      } else {
        throw new Error(data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching same street data:', error);
      this.snackbar = true;
      this.message = "Failed to fetch same street data. You can now enter data manually.";
      this.sameStreetDataFetchFailed = true;

      // Initialize empty fields for manual entry
      this.property.onStreetCompare = ['', '', ''];
    } finally {
      this.loadingSameStreetData = false;
    }
  },

  //Previously Sold - Within Same Area

  async fetchSameAreaData() {
    this.loadingSameAreaData = true;
    this.sameAreaDataFetchFailed = false;
    console.log("Fetching previously sold - within same area data...");
    const address = this.property.address;
    let postcode = address.split(', ').pop().replace(/\s/g, '');
    
    console.log("Extracted postcode:", postcode);
    
    try {
      const url = `https://api.propertydata.co.uk/sold-prices?key=PWQYTWQ1NQ&postcode=${postcode}&max_age=12`;
      console.log("API URL:", url);
      
      const response = await fetch(url);
      console.log("Response status:", response.status);
      
      const data = await response.json();
      console.log("API response:", data);
      
      if (data.status === 'success' && data.data && data.data.raw_data) {
        // Sort the properties by date in descending order and take the top 3
        const soldProperties = data.data.raw_data
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3); // Take only the top 3 results
        
        this.property.onDistrictCompare = soldProperties.map(property => 
          `${property.address} - £${property.price.toLocaleString()} - ${property.date}`
        );
        console.log("Same area data fetched successfully:", this.property.onDistrictCompare);
        this.snackbar = true;
        this.message = "Same area data fetched successfully";
      } else {
        throw new Error(data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching same area data:', error);
      this.snackbar = true;
      this.message = "Failed to fetch same area data. You can now enter data manually.";
      this.sameAreaDataFetchFailed = true;

      // Initialize empty fields for manual entry
      this.property.onDistrictCompare = ['', '', ''];
    } finally {
      this.loadingSameAreaData = false;
    }
  },

  async fetchForSaleAreaData() {
  this.loadingForSaleAreaData = true;
  this.forSaleAreaDataFetchFailed = false;
  console.log("Fetching for sale - within the area data...");
  const address = this.property.address;
  let postcode = address.split(', ').pop().trim().replace(' ', '-').toLowerCase();
  const radiusValues = [0, 0.25, 0.5, 1, 3, 5];
  let currentRadiusIndex = 0;
  let listings = [];

  try {
    // Use a public CORS proxy
    const corsProxy = 'https://api.codetabs.com/v1/proxy?quest=';

    // Fetch location ID
    const housePricesUrl = `https://www.rightmove.co.uk/house-prices/${postcode}.html`;
    const housePricesResponse = await fetch(corsProxy + encodeURIComponent(housePricesUrl));
    const housePricesHtml = await housePricesResponse.text();
    const match = housePricesHtml.match(/__PRELOADED_STATE__ = ({.*?})</);
    if (!match) {
      throw new Error('Unable to find preloaded state data');
    }
    const preloadedState = JSON.parse(match[1]);
    const locationId = preloadedState.searchLocation.locationId;

    const bedroomCount = parseInt(this.property.bedrooms) || 0;
    const bedroomFilter = bedroomCount > 0 ? `&minBedrooms=${bedroomCount}&maxBedrooms=${bedroomCount}` : '';

    // Fetch listings
    while (listings.length < 3 && currentRadiusIndex < radiusValues.length) {
      const radius = radiusValues[currentRadiusIndex];
      const listingsUrl = `https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=POSTCODE^${locationId}&sortType=6&viewType=LIST&channel=BUY&index=0&radius=${radius}${bedroomFilter}`;
      
      console.log(`Fetching for sale listings (radius ${radius} miles):`, listingsUrl);
      const listingsResponse = await fetch(corsProxy + encodeURIComponent(listingsUrl));
      const listingsHtml = await listingsResponse.text();
      
      const parser = new DOMParser();
      const doc = parser.parseFromString(listingsHtml, 'text/html');
      
      doc.querySelectorAll('.propertyCard').forEach((card) => {
        if (listings.length < 3) {
          const address = card.querySelector('.propertyCard-address')?.textContent.trim();
          const price = card.querySelector('.propertyCard-priceValue')?.textContent.trim();
          if (address && price) {
            listings.push(`${address} - ${price}`);
          }
        }
      });
      
      currentRadiusIndex++;
    }

    if (listings.length === 0) {
      throw new Error('No listings found');
    }

    this.property.onForSaleCompare = listings;
    console.log("For sale data fetched successfully:", this.property.onForSaleCompare);
    this.snackbar = true;
    const radius = radiusValues[currentRadiusIndex - 1];
    this.message = `For Sale Data Fetched Successfully at a ${radius} Mile Radius`;
  } catch (error) {
    console.error('Error fetching for sale area data:', error);
    this.snackbar = true;
    this.message = "Failed to fetch for sale area data. You can now enter data manually.";
    this.forSaleAreaDataFetchFailed = true;
    this.property.onForSaleCompare = ['', '', ''];
  } finally {
    this.loadingForSaleAreaData = false;
  }
},


// For Rent - Within The Area

async fetchForRentAreaData() {
  this.loadingForRentAreaData = true;
  this.forRentAreaDataFetchFailed = false;
  console.log("Fetching for rent - within the area data...");
  
  const address = this.property.address;
  let postcode = address.split(', ').pop().trim().replace(' ', '-').toLowerCase();

  const radiusValues = [0, 0.25, 0.5, 1, 3, 5];
  let currentRadiusIndex = 0;
  let listings = [];

  try {
    // Use the same proxy as for sale function
    const corsProxy = 'https://api.codetabs.com/v1/proxy?quest=';

    // Fetch location ID
    const housePricesUrl = `https://www.rightmove.co.uk/house-prices/${postcode}.html`;
    const housePricesResponse = await fetch(corsProxy + encodeURIComponent(housePricesUrl));
    const housePricesHtml = await housePricesResponse.text();

    const match = housePricesHtml.match(/__PRELOADED_STATE__ = ({.*?})</);
    if (!match) {
      throw new Error('Unable to find preloaded state data');
    }
    const preloadedState = JSON.parse(match[1]);
    const locationId = preloadedState.searchLocation.locationId;

    const bedroomCount = parseInt(this.property.bedrooms) || 0;
    const bedroomFilter = bedroomCount > 0 ? `&minBedrooms=${bedroomCount}&maxBedrooms=${bedroomCount}` : '';
    const bedroomText = bedroomCount > 0 ? `${bedroomCount} Bedrooms` : 'Any Bedrooms';

    // Fetch rental listings
    while (listings.length < 3 && currentRadiusIndex < radiusValues.length) {
      const radius = radiusValues[currentRadiusIndex];
      const listingsUrl = `https://www.rightmove.co.uk/property-to-rent/find.html?locationIdentifier=POSTCODE^${locationId}&sortType=6&viewType=LIST&channel=RENT&index=0&radius=${radius}${bedroomFilter}&propertyTypes=&includeLetAgreed=false&mustHave=&dontShow=&furnishTypes=&keywords=`;

      console.log(`Fetching rental listings from URL (radius ${radius} miles):`, listingsUrl);
      const listingsResponse = await fetch(corsProxy + encodeURIComponent(listingsUrl));
      const listingsHtml = await listingsResponse.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(listingsHtml, 'text/html');

      doc.querySelectorAll('.propertyCard').forEach((card) => {
        if (listings.length < 3) {
          const address = card.querySelector('.propertyCard-address')?.textContent.trim();
          const price = card.querySelector('.propertyCard-priceValue')?.textContent.trim();

          if (address && price) {
            listings.push(`${address} - ${price} - ${bedroomText}`);
          }
        }
      });

      currentRadiusIndex++;
    }

    if (listings.length === 0) {
      throw new Error('No rental listings found');
    }

    this.property.onForRentCompare = listings;
    console.log("For rent data fetched successfully:", this.property.onForRentCompare);
    this.snackbar = true;
    const radius = radiusValues[currentRadiusIndex - 1];
    this.message = `For Rent Data Fetched Successfully at a ${radius} Mile Radius`;
  } catch (error) {
    console.error('Error fetching for rent area data:', error);
    this.snackbar = true;
    this.message = "Failed to fetch for rent area data. You can now enter data manually.";
    this.forRentAreaDataFetchFailed = true;

    // Initialize empty fields for manual entry
    this.property.onForRentCompare = ['', '', ''];
  } finally {
    this.loadingForRentAreaData = false;
  }
},

// Fetching nearest stations data

async fetchNearestStations() {
  this.loadingStationsData = true;
  console.log("Fetching nearest stations...");
  const address = this.property.address;
  let postcode = address.split(', ').pop().trim().replace(/\s/g, '');

  console.log("Extracted postcode:", postcode);

  try {
    // Step 1: Get Latitude and Longitude from Postcodes.io
    const geoResponse = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
    const geoData = await geoResponse.json();

    if (geoData.status !== 200) {
      throw new Error('Failed to fetch geolocation data');
    }

    const { latitude, longitude } = geoData.result;

    console.log("Geolocation:", latitude, longitude);

    // Step 2: Get Nearest Stations from TransportAPI
    const transportUrl = `https://transportapi.com/v3/uk/places.json?app_id=a90987e1&app_key=584c7eb1e8ca20e41769d9b18dc043cd&lat=${latitude}&lon=${longitude}&type=train_station&rpp=3`;
    const transportResponse = await fetch(transportUrl);
    const transportData = await transportResponse.json();

    console.log("Transport API response:", transportData);

    if (!transportData.member || transportData.member.length === 0) {
      throw new Error('No stations found');
    }

    // Map the stations data to the desired format (limit to 3)
    this.property.stations = transportData.member.slice(0, 3).map(station => ({
      station: station.name,
      distance: this.convertMetresToMiles(station.distance)
    }));

    console.log("Nearest stations data fetched successfully:", this.property.stations);
    this.snackbar = true;
    this.message = "Nearest stations data fetched successfully";
  } catch (error) {
    console.error('Error fetching nearest stations data:', error);
    this.snackbar = true;
    this.message = "Error fetching nearest stations data: " + error.message;
  } finally {
    this.loadingStationsData = false;
  }
},


    // Helper method to convert metres to miles
    convertMetresToMiles(metres) {
      const miles = metres / 1609.344;
      return miles.toFixed(2) + " Miles";
    },


    // Updated Financial Section

    setDefaultValues() {
      this.property.mortgageLabel = 'N/A';
      this.property.reservationPrice = this.property.reservationPrice || 100;
      this.property.legals = this.property.legals || 2000;
      if (!this.property.deposite) this.property.deposite = 0;
      if (!this.property.setupFee) this.property.setupFee = 500;
      if (!this.property.management) this.property.management = 0;
      if (!this.property.opex) this.property.opex = 0;
      if (!this.property.stampDuty) this.property.stampDuty = 0;
      if (!this.property.grossYield) this.property.grossYield = 0;
      if (!this.property.brokerage) this.property.brokerage = 0;
      if (!this.property.totalInvestment) this.property.totalInvestment = 0;
      if (!this.property.discount) this.property.discount = 0;
      if (!this.property.discountLabel) this.property.discountLabel = "0% Discount";
    },

    getFieldValue(model) {
      const value = this.property[model];
      if (model === 'grossYield' || model === 'discount' || model === 'netReturn') {
      return value ? value.toFixed(2) + '%' : '';
     }
      if (model === 'discountLabel' || model === 'mortgageLabel') {
      return value;
      }
      if (model === 'discount') {
        return value ? value.toFixed(2) + '%' : '';
      }
      if (model === 'mortgageLabel') {
        return value || 'N/A';
      }
      if (model === 'opex') {
        return this.formatCurrency(value);
      }
      return this.formatCurrency(value);
    },

    updateFieldValue(model, value) {
      // Remove currency symbol and commas
      const numericValue = value.replace(/[^0-9.-]+/g, '');
      const parsedValue = parseFloat(numericValue);
      
      if (!isNaN(parsedValue)) {
        this.property[model] = parsedValue;
        
        if (model === "purchasePrice") {
          this.property.deposite = parsedValue ? parsedValue / 4 : 0;
          this.property.brokerage = parsedValue ? (parsedValue * 0.75) * 0.01 : 0;
        }
        
        this.calculateFinancials();
        this.calculateStampDuty();
      }
    },

    calculateManagementFees() {
      const currentRent = parseFloat(this.property.currentRent) || 0;
      const estimatedRent = parseFloat(this.property.averageRent) || 0;
      const maxRent = Math.max(currentRent, estimatedRent);
      this.property.management = Math.ceil(maxRent * 0.10);
    },

    calculateNetCashflow() {
      const currentRent = parseFloat(this.property.currentRent) || 0;
      const estimatedRent = parseFloat(this.property.averageRent) || 0;
      const maxRent = Math.max(currentRent, estimatedRent);
      const managementFees = parseFloat(this.property.management) || 0;
      const operatingExpenses = parseFloat(this.property.opex) || 0;
      const mortgage = parseFloat(this.property.mortgage) || 0;
      const netCashflow = maxRent - managementFees - mortgage;
      this.property.netCashflow = Math.max(0, Math.ceil(netCashflow));
    },

    calculateAnnualCashflow() {
      const netCashflow = parseFloat(this.property.netCashflow) || 0;
      const annualCashflow = netCashflow * 12;
      this.property.netAnnualCashflow = Math.max(0, Math.ceil(annualCashflow));
    },

    calculateFinancials() {
      const purchasePrice = parseFloat(this.property.purchasePrice) || 0;
      const estimatedRent = parseFloat(this.property.averageRent) || 0;
      const currentRent = parseFloat(this.property.currentRent) || 0;
      const maxRent = Math.max(currentRent, estimatedRent);

      

      // Calculate Deposit (25% of Purchase Price)
      this.property.deposite = Math.ceil(purchasePrice / 4);

      // Calculate Brokerage (1% of borrowing, assuming 75% borrowing)
      this.property.brokerage = Math.ceil((purchasePrice * 0.75) * 0.01);

      // Calculate Mortgage (5.5% interest annually)
      this.property.mortgage = Math.ceil(((purchasePrice * 0.75) * 0.055) / 12);

      // Calculate Management Fees
      this.calculateManagementFees();

      // Calculate Operating Expenses (20% of rent as operating expenses)
      this.property.opex = Math.ceil(maxRent * 0.2);

      // Calculate Gross Yield
      if (purchasePrice > 0) {
      const grossYield = ((maxRent * 12) / purchasePrice) * 100;
      // Round to nearest 0.1
      this.property.grossYield = Math.ceil(grossYield * 10) / 10;
    } else {
      this.property.grossYield = 0;
    }

      // Calculate Total Investment
      this.property.totalInvestment = Math.ceil(
        this.property.deposite +
        this.property.brokerage +
        this.property.legals +
        this.property.setupFee +
        this.property.stampDuty +
        this.property.reservationPrice
      );

      // Calculate Net Cashflow and Annual Cashflow
      this.calculateNetCashflow();
      this.calculateAnnualCashflow();

      //Calulate Discount
      const estimatedValue = parseFloat(this.property.price) || 0;
        if (estimatedValue > 0 && purchasePrice > 0) {
          const discountPercentage = ((estimatedValue - purchasePrice) / estimatedValue) * 100;
          this.property.discount = Math.max(0, Math.ceil(discountPercentage * 10) / 10); // Round up to nearest 0.1
          this.property.discountLabel = `${this.property.discount.toFixed(2)}% Discount`;
        } else {
          this.property.discount = 0;
          this.property.discountLabel = "0% Discount";
        }

        // Calculate net return (assuming this is annual return on investment)
        if (this.property.totalInvestment > 0) {
          this.property.netReturn = Math.ceil((this.property.netAnnualCashflow / this.property.totalInvestment) * 100);
        } else {
          this.property.netReturn = 0;
      }
    },

    calculateStampDuty() {
      let price = parseFloat(this.property.purchasePrice) || 0;
      let stampDuty = 0;

      // First £250,000 at 3%
      if (price > 250000) {
        stampDuty += 250000 * 0.03;
      } else {
        stampDuty += price * 0.03;
      }

      // Next £675,000 (between £250,000 and £925,000) at 8%
      if (price > 250000) {
        const remaining = price - 250000;
        if (remaining > 675000) {
          stampDuty += 675000 * 0.08;
        } else {
          stampDuty += remaining * 0.08;
        }
      }

      // Remaining amount over £925,000 at 13%
      if (price > 925000) {
        const remaining = price - 925000;
        if (remaining > 575000) {
          stampDuty += 575000 * 0.13;
        } else {
          stampDuty += remaining * 0.13;
        }
      }

      // Extra for properties over £1.5M at 15%
      if (price > 1500000) {
        const remaining = price - 1500000;
        stampDuty += remaining * 0.15;
      }

      this.property.stampDuty = Math.ceil(stampDuty);
    },

    formatCurrency(value) {
      if (value === null || value === undefined) return '£0';
      return '£' + parseFloat(value).toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
    },
  watch: {
    'property.purchasePrice': function () {
      this.calculateFinancials();
      this.calculateStampDuty();
    },
    'property.averageRent': function () {
      this.calculateFinancials();
    },
    'property.currentRent': function () {
      this.calculateFinancials();
    },
    'property.legals': function () {
      this.calculateFinancials();
    },
    'property.setupFee': function () {
      this.calculateFinancials();
    }
  },

  watch: {
  dealBenefitText: {
    handler() {
      this.property.onBenefits = this.dealBenefitText;
    },
    immediate: true
  }
},

  async created() {
    await this.getProperty(this.$route.params.id);
    this.property = this.allOffMarketProperty.results;

    // Ensure schools array is initialized
  if (!this.property.schools || !Array.isArray(this.property.schools)) {
    this.property.schools = [];
  }

  if (!this.property.stations) {
    this.property.stations = [];
  }

    this.loading = false;

    // Initialize financials when the component is created
    this.calculateFinancials();
    this.calculateStampDuty();
    this.setDefaultValues();
  },
};
</script>



<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.v-text-field input {
  text-align: right;
}

.section {
  padding: 20px;
  background-color: #2483C5;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #333;
    color: white;

    ::v-deep .v-text-field input,
    ::v-deep .v-select__selection,
    ::v-deep .v-select__selection-text,
    ::v-deep .v-textarea textarea {
      color: white !important;
    }

    ::v-deep .v-text-field .v-label,
    ::v-deep .v-select .v-label {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    ::v-deep .v-input__slot:before,
    ::v-deep .v-input__slot:after {
      border-color: white !important;
    }
  }
}

h3 {
    font-size: 24px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 25px;
  }


.locked-field-section {
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }

  .section-header,
  ::v-deep .v-input {
    position: relative;
    z-index: 1;
  }
}

.section-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.iconText {
  span {
    font-size: 16px;
    font-style: bold;
    display: block;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
}

.images {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  li {margin-right: 10px;
  }
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.image-item {
  position: relative;
  cursor: move;
}

.closeBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  background: red;
  color: #fff;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  font-size: 14px;
}

.flexBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h2 {
    margin-top: 5px;
  }
}

.fixedLoading {
  position: fixed;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
}

.width100 {
  width: 100% !important;
  .v-btn {
    width: 80%;
  }
}

.flex-box-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.uk-sticky {
  margin: 0;
  z-index: 999999;
  width: 200px;
  text-decoration: none;
}

.section {
  position: relative;
  padding-bottom: 60px;

  .update-btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333 !important;
    color: rgba(255, 255, 255, 1) !important;
    transition: background-color 0.3s, color 0.3s;
  }

  &:hover {
    .update-btn {
      background-color: #2483C5 !important;
      color: white !important;

      &:hover {
        background-color: #616161 !important; // Slightly lighter grey on hover
      }
    }
  }
}

// Override Vuetify's default button styles
.v-btn.update-btn::before {
  background-color: transparent;
}

.add-data-btn {
  background-color: #333 !important;
  color: rgba(255, 255, 255, 0.7) !important;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.8rem;
  padding: 0 12px;
  height: 28px;
  margin-bottom: 16px !important;
  width:125px;

  &:hover {
    background-color: #2483C5 !important;
    color: white !important;
  }
}

.section:hover .add-data-btn {
  background-color: #2483C5 !important;
  color: white !important;
}

.school-section {
  margin-bottom: 16px;
}


.iconText {
  span {
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
  }
}

.locked-field {
  background-color: rgba(0, 0, 0, 0.3);
}

.v-text-field.locked-field input {
  color: rgba(0, 0, 0, 0.6);
}



.theme--light.v-input input, .theme--light.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}

.lock-icon {
  background-color: black !important;
  
  .v-icon {
    color: white !important;
  }
  
  &:hover {
    background-color: #2483C5 !important;
    
    .v-icon {
      color: black !important;
    }
  }
}

.section:hover .lock-icon {
  background-color: #2483C5 !important;
  
  .v-icon {
    color: black !important;
  }
}

.modal-btn {
  background-color: #2483C5 !important;
  color: white !important;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: black !important;
    color: white !important;
  }

  span {
    color: white !important;
  }
}

// Override Vuetify's default hover effect
.v-btn:before {
  opacity: 0 !important;
}

//Spacer 

.deal-benefit-section {
  .deal-benefit-spacer {
    height: 28px; // Adjust this value to match the height of your "Add Data" buttons
    margin-bottom: 20px; // Adjust this to match the margin-bottom of your "Add Data" buttons
  }

  span {
    margin-bottom: 5px;
  }
}

// Ensure consistent spacing for all sections in Additional Details
.flexBox.iconText {
  display: flex;
  flex-direction: column;

  > span {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .v-btn.add-data-btn,
  .deal-benefit-spacer {
    margin-bottom: 16px;
  }
}

//Other Details button

.data-field {
  position: relative;

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    span {
      font-size: 15px;
    }
  }

  .add-data-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.7rem;
    padding: 0 8px;
    height: 24px;
    background-color: #424242 !important; // Dark grey background
    color: white !important;
  }

  .v-text-field {
    margin-top: 28px; // Adjust this value to create space for the button
  }
}

// Override Vuetify's default button styles
.v-btn.add-data-btn::before {
  background-color: transparent;
}

.v-btn.add-data-btn:hover {
  background-color: #616161 !important; // Slightly lighter grey on hover
}

.section:hover {
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: white !important;
  }
}

.locked-field-container {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
    border-radius: 4px;
  }

  > div {
    position: relative;
    z-index: 1;
  }

  span, .v-input {
    position: relative;
    z-index: 1;
  }
}

.v-text-field,
.v-select {
  &.v-input--is-focused {
    ::v-deep .v-input__slot {
      border-color: #1976d2 !important;
      box-shadow: 0 0 0 1px #1976d2 !important;
    }
  }

  &:hover {
    ::v-deep .v-input__slot {
      border-color: #1976d2 !important;
    }
  }
}

.section:hover {
  .locked-field-container::before {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .locked-field-container {
    span, .v-input input {
      color: white !important;
    }
  }
}
</style>